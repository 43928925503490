import React from 'react'
// import Header from '../../header'
import HeroSection from '../../heroSection'
// import Footer from '../../footer'
import Gallery from '../../gallery'
import AboutSection from '../../aboutSection'

function Home() {
  return (
    <div>
        <HeroSection/>
        <AboutSection/>
        <Gallery/>
    </div>
  )
}

export default Home