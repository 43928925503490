import React from 'react'
import image from '../../assets/images/Tatia.jpg'
import Button from '../button'

function AboutSection() {
  return (
    <div className=' lg:mx-[20%] lg:flex lg:items-center lg:my-24 gap-24 mx-4'>
      <img src={image} alt="Ketevan" className='w-[40%] mx-auto'/>
      <div className='lg:text-left text-center my-8 lg:my-0'>
        <h1 className='text-center md:text-left max-w-2xl mb-8 text-4xl tracking-tight leading-none md:text-5xl xl:text-6xl'>ABOUT</h1>
        <p className='text-center md:text-left max-w-2xl mb-8 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl'>
          Ketevan is a contemporary figurative artist with a degree in visual arts (sculpture) that she received from the Tbilisi State Academy of Arts. Despite her degree, she currently works in two dimensions: drawings and paintings. Her technical background has a strong influence on the way space, colors, values, and shapes are manipulated and expressed. Easily perceived, realistic appearance in figures and complexity of emotional charge are the main aspects of her paintings.
        </p>
        <Button link={'/about'} text={'Read more'}/>
      </div>
    </div>
  )
}

export default AboutSection