import React from 'react'

function GalleryPicture({title, date, size, price, material, url, setPictureClicked, pictureClicked, setCurrentIndex, keyInfo}) {
  return (
    <div 
      className='grid justify-center relative group' 
      onClick={() => {
        setPictureClicked(!pictureClicked);
        setCurrentIndex(keyInfo);
      }} 
      key={keyInfo}>
      <div>
        <img src={url} alt={title} className='object-cover w-full aspect-square group-hover:object-scale-down'/>
        <div className='absolute text-left bottom-0 bg-black/80 text-white w-0 group-hover:w-4/5 group-hover:p-4 transition-width duration-300 ease-in-out'>
            <h3 className='font-bold invisible group-hover:visible'>{title}</h3>
            <p className='invisible group-hover:visible'>Size: {size}</p>
        </div>
      </div>
    </div>
  )
}

export default GalleryPicture