import React, { useState } from 'react'

function OpenedPicture({data, setCurrentIndex, dataSize}) {
  const imageUrl = require('../../../assets/gallery/'+ data.url);
  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : dataSize - 1));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex < dataSize - 1 ? prevIndex + 1 : 0));
  };

  return (
    <div className="flex justify-center items-center">
      <div className="w-[80%] fixed top-0 z-40 h-[80vh] mt-[5vh] bg-black bg-opacity-90 rounded-lg group">
        <div className="hidden absolute bottom-0 z-50 p-5 bg-black/70 group-hover:block rounded-lg">
          <p className="text-white">
            Title: {data.title}
          </p>
          <p className="text-white">
            Date: {data.date}
          </p>
          <p className="text-white">
            Size: {data.size}
          </p>
          <p className="text-white">
            Material {data.material}
          </p>
          <p className="text-white">
            {data.price === '' ? `Price: $${data.price}` : null}
          </p>
        </div>
        <div className="relative h-56 overflow-hidden rounded-lg md:h-[80vh]">
          <div className="duration-700 ease-in-out flex justify-center">
            <img
              src={imageUrl}
              className="object-contain h-[80vh]"
              alt={data.title}
            />
          </div>
        </div>
        <button type="button" class="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" onClick={handlePrevClick}>
          <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg className="w-4 h-4 text-white dark:text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 1 1 5l4 4"/>
            </svg>
            <span className="sr-only">Previous</span>
          </span>
        </button>
        <button type="button" className="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" onClick={handleNextClick}>
          <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
            <svg className="w-4 h-4 text-white dark:text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
            </svg>
            <span className="sr-only">Next</span>
          </span>
        </button>
      </div>
    </div>

  )
}

export default OpenedPicture