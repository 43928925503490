import React from 'react'
import { useForm, ValidationError } from '@formspree/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

function Contact() {
  const formspreeCode = process.env.REACT_APP_FORMSPREE_CODE;
  const [state, handleSubmit] = useForm(formspreeCode);
  if (state.succeeded) {
      return (
              <div className="flex items-center justify-center bg-green-500 text-white py-6 px-8 rounded-md">
                <i className="fas fa-check-circle text-4xl mr-4"></i>
                <p className="text-xl font-semibold">Message Sent Successfully, Thank You!</p>
              </div>
      )
  }
    return (
      <div className="bg-white text-black h-screen flex flex-col items-center justify-center">
      <h1 className="text-4xl font-bold mb-6">Contact Us</h1>
      <p className='mx-4 md:mx-0 md:w-[50%] text-center'>
        Whether you have inquiries about my art, are interested in purchasing a piece, order a commission or want to collaborate on a creative project, please don't hesitate to get in touch. Your feedback and ideas are always welcome.
      </p>
      <a href='mailto:anasovi.ketevan@gmail.com?subject = Feedback&body = Message' className='flex place-items-center mt-8'>
          <FontAwesomeIcon icon={faEnvelope} className='pr-4 h-5'/>
          <p>anasovi.ketevan@gmail.com</p>
      </a>
      <div className="bg-black bg-opacity-10 p-8 rounded-full text-center">
        <form onSubmit={handleSubmit}>
          <input
            className="w-full p-2 text-black bg-transparent border rounded-full focus:outline-none mb-4"
            type="text"
            placeholder="Your Name"
            name="name"
            id="name"
          />
          <input
            className="w-full p-2 text-black bg-transparent border rounded-full focus:outline-none mb-4"
            type="email"
            placeholder="Your Email"
            name="email"
            id="email"
          />
          <textarea
            className="w-full p-2 text-black bg-transparent border rounded-lg focus:outline-none mb-6"
            placeholder="Your Message"
            rows="4"
            name="message"
            id="message"
          />
          <ValidationError 
            prefix="Message" 
            field="message"
            errors={state.errors}
          />
          <button type="submit" disabled={state.submitting} className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white bg-black hover:bg-orange-500">
            Send
            <svg className="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
          </button>
        </form>
      </div>
    </div>
    );
}

export default Contact