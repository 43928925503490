import React from 'react'
import { Link } from 'react-router-dom'
import './index.css'

function Card({title, image, info}) {
  const background = 'bg-' + image
  return (
    <Link to={'/gallery/'+title}>
      <article className={"mx-auto my-8 max-w-sm shadow-xl bg-cover bg-center min-h-150 transform duration-500 hover:-translate-y-2 cursor-pointer group " + background  }>
          <div className="bg-black bg-opacity-20 min-h-150 px-10 flex flex-wrap flex-col pt-96 hover:bg-opacity-75 transform duration-300 pb-4">
              <h1 className="text-white text-3xl mb-5 transform translate-y-20 group-hover:translate-y-0 duration-300 text-left">
                  {title}
              </h1>
              <div className="w-16 h-2 bg-orange-500 rounded-full mb-5 transform translate-y-20 group-hover:translate-y-0 duration-300">
              </div>
              <p className="opacity-0 text-white text-xl group-hover:opacity-80 transform duration-500 text-left">
                  {info}
              </p>
          </div>
      </article>
    </Link>
  )
}

export default Card