import React from 'react'
import heroImg from './../../assets/images/hero-img.jpg'

function HeroSection() {
  return (
    <section className="bg-white">
      <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-x-20 xl:gap-x-20 lg:py-16 lg:grid-cols-12">
          <div className="mr-auto place-self-center lg:col-span-5 lg:text-left">
              <h1 className="text-center md:text-left max-w-2xl mb-2 text-6xl font-extrabold tracking-tight leading-none md:text-7xl xl:text-8xl">ANASOVI</h1>
              <h1 className="text-center md:text-left max-w-2xl mb-10 text-6xl font-extrabold tracking-tight leading-none md:text-7xl xl:text-8xl">KETEVAN</h1>
              <p className="text-center md:text-left max-w-2xl mb-10 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl">An up and coming Georgian artist, who has risen as a formidable figure in the art market by displaying her unique pespective of the world with her art.</p>
          </div>
          <div className="hidden lg:mt-0 lg:col-span-7 lg:flex">
              <img src={heroImg} alt="mockup"/>
          </div>                
      </div>
  </section>
  )
}

export default HeroSection