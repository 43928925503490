import React from 'react'
import './index.css'

function About() {
  return (
    <div className='mx-4 text-center md:text-left md:mx-64 md:mb-32'>
      <h1 className='my-12'>Ketevan Anasovi</h1>
      <p className='my-1'>Born in 1999 in Sachkhere, Georgia</p>
      <p className='my-1'>Resides and works in Tbilisi, Georgia</p>
      <h4 className='my-4 mt-8'>Education/Training:</h4>
      <p className='my-1'>2005 – 2015 - Become member of young artist union of Georgia at Iakob GogebaShivili Private School-Academy for Talents. Tbilisi, Georgia</p>
      <p className='my-1'>2016 – 2017 – Additional artistic training at Ilia ChavChavadze additional art school, Tbilisi, Georgia</p>
      <p className='my-1'>2017 – 2022 – Bachelor of Sculpture at Tbilisi State Academy of Arts. Tbilisi, Georgia.</p>
      <h4 className='my-4 mt-8'>Selected group exhibitions:</h4>
      <p className='my-1'>2023 “Professional graphic artists-2023” Week of art, Tbilisi, Georgia</p>
      <p className='my-1'>2022 “Bi auction for young artists”,  Iart Gallery, Tbilisi, Georgia</p>
      <p className='my-1'>2022  “Solidarity action“,  Tbilisi state academy of arts  Tbilisi, Georgia</p>
      <p className='my-1'>2022  “Three Generations”,  Merab Berdzenishvili Cultural Center “Muza”, Tbilisi, Georgia</p>
      <p className='my-1'>2022  “Sculpture exhibition”, in Zurab Thsereteli Musaum of Modern art, Tbilisi, Georgia</p>
      <p className='my-1'>2021  “live Painting”, Art Tent, Tbilisi, Gorgia</p>
      <p className='my-1'>2021 “Bi auction for young artists”,  Iart Gallery, Tbilisi, Georgia</p>
      <p className='my-1'>2020  “Bi auction for young artists”,  Iart Gallery, Tbilisi, Georgia</p>
      <h4 className='my-4 mt-8'>Selected collections:</h4>
      <p className='my-1'>Private Collection, “Little Anna” Tbilisi. Georgia </p>
      <p className='my-1'>Private Collection, “Dream” Ankara, Turkey</p>
      <p className='my-1'>Private Collection, “Is it love or manipulation? 7/25” Moscow, Russia</p>
      <p className='my-1'>Private Collection, “I can see through you” “Loved ones” Columbus, USA</p>
      <p className='my-1'>Private Collection, “Male figure” “female figure” Wien. Austria</p>
      <p className='my-1'>Private Collection, “Flowers” Mont Richard. France</p>
      <p className='my-1'>Private Collection, “Depression” Berlin, Germany</p>
      <p className='my-1'>Private Collection, “Helping Hand”, Honing, Sweden</p>
      <p className='my-1'>Private Collection, “Blue monochrome sketch” Tbilisi. Georgia</p>
      <p className='my-1'>Private Collection, “Pain” Tbilisi. Georgia</p>
      <p className='my-1'>Private Collection, “hug”, “Looking you”, “Harpy”, “Tree from edam’s garden”, “Depression”, “Plant- quarantine days”, Tbilisi. Georgia</p>
      <p className='my-1'>Private Collection, “man’s portrait blue monochrome sketch” Tbilisi. Georgia</p>
      <p className='my-1'>Private Collection, “blue monochrome sketch - women” “blue monochrome sketch – men” Spain, Gran Canarias.</p>
      <p className='my-1'>Private Collection, “Hopeless” England, Nottingham</p>
      <p className='my-1'>Private Collection, “blue monochrome sketch - women” England, Pew sham</p>
      <p className='my-1'>Private Collection, “pink monochrome sketch - women” Denmark, Copenhagen</p>
      <h4 className='my-4 mt-8'>Selected group exhibitions and award from childhood:</h4>
      <p className='my-1'>2009 “Protecting kids rights” Elene Akhvleviani Children Gallery, Tbilisi, Georgia</p>
      <p className='my-1'>2009 “The resurrection” Elene Akhvleviani Children Gallery, Tbilisi, Georgia</p>
      <p className='my-1'>2008 ‘’Beautiful drawing and writing“ competition Winner. Tbilisi, Georgia</p>
      <p className='my-1'>2006 “The resurrection” Elene Akhvleviani Children Gallery, Tbilisi, Georgia</p>
      <p className='my-1'>2006 „We are future olimpians” Elene Akhvleviani Children Gallery, Tbilisi, Georgia</p>
      <p className='my-1'>2005 “The future is ouers” Elene Akhvleviani Children Gallery, Tbilisi, Georgia</p>
      <h4 className='my-4 mt-8'>Selected publications and press:</h4>
      <p className='my-1'>“Three generations of Georgian sculptors of the latest period 1990s/2000s/2010s” exhibition catalog. The Cultural Events Center of Tbilisi City Hall. 2022</p>
      <p className='my-1'>“Meet the artist –Ketevan Anasovi” online magazine “Grlzwave” Tbilisi, Georgia. 2022</p>
      <p className='my-1'>“art critique – Ketevan Anasovi” online magazine “perla.ge” Tbilisi, Georgia 2023 </p>
      <p className='my-1'>“Ketevan Anasovi” https://artfacts.net/artist/ketevan-anasovi/887435 Art facts. Online international art platform. 2023</p>
      <p className='my-1'>“Issua 4” Entheoscope - independent biannual magazine. 2023</p>
    </div>
  )
}

export default About