import React from 'react'
import { useParams } from 'react-router-dom'
import { useState, useEffect,useRef } from 'react';
import GalleryPicture from './galleryPicture';
import OpenedPicture from './openedPicture';

function GalleryPage() {
  const { category } = useParams()
  const [data, setData] = useState(null);
  const [pictureClicked, setPictureClicked] = useState(false);
  const [categoryData, setCategoryData] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const openedPictureRef = useRef(null);

  useEffect(() => {
    fetch('/gallery.json')
      .then(response => {
        return response.json();
      })
      .then(data => {
        setData(data.gallery);
        const filteredData = data.gallery.filter(item => item.category.toLowerCase() === category.toLowerCase());
        setCategoryData(filteredData);
      })
      .catch(error => console.error('Error fetching gallery data:', error));
  }, [category]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        openedPictureRef.current &&
        !openedPictureRef.current.contains(event.target)
      ) {
        setPictureClicked(false);
        setCurrentIndex(null);
      }
    };
    if (pictureClicked) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [pictureClicked]);
  
  return (
    <div>
      <div class='grid grid-cols-1 md:grid-cols-3 gap-6 place-content-around mx-4 md:mx-60 my-24'>
          {
            categoryData?.map((item, index) => {
              // const imageUrl = process.env.PUBLIC_URL + 'src/assets/gallery/' + item.url;
              const imageUrl = require('../../../assets/gallery/'+ item.url);
              return <GalleryPicture
                keyInfo={index} 
                title={item.title} 
                date={item.date}
                size={item.size}
                material={item.material}
                price={item.price}
                url={imageUrl}
                pictureClicked={pictureClicked}
                setPictureClicked={setPictureClicked}
                setCurrentIndex={setCurrentIndex}/>
            })
          }
      </div>
      { pictureClicked ?
        <div ref={openedPictureRef}>
          <OpenedPicture data={categoryData[currentIndex]} setCurrentIndex={setCurrentIndex} dataSize={categoryData.length} /> 
        </div>  
        : 
        null}
      </div> 
      
  )
}

export default GalleryPage