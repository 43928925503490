import React from 'react'
import Card from './card'


function Gallery() {
  return (
    <div className='lg:flex gap-8 mx-16' id="gallery-section">
      <Card title={'Sold'} image={'sold'} info={'Selected two-dimensional works'}/>
      <Card title={'Sculpture'} image={'sculpture'} info={'Selected three-dimensional works'}/>
      <Card title={'Drawings'} image={'drawings'} info={'Artworks that have found homes'}/>
      <Card title={'Available'} image={'available'} info={'Artwork ready for your collection'}/>
    </div>
  )
}

export default Gallery