import React from 'react'
import logo from '../../assets/images/logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMugHot } from '@fortawesome/free-solid-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'


function Footer() {
  return (
    <div className='border-t-2 px-24'>
        <img src={logo} alt="anasovi" className='h-24 mx-auto my-8'/>
        <div className="lg:grid lg:grid-cols-3 justify-center justify-items-center mb-8 text-center">
            <a href='https://www.instagram.com/anasovi__k/' className='flex place-items-center'>
                <FontAwesomeIcon icon={faInstagram} className='pr-4 h-5'/>
                <p>anasovi__k</p>
            </a>
            <a href='mailto:anasovi.ketevan@gmail.com?subject = Feedback&body = Message' className='flex place-items-center'>
                <FontAwesomeIcon icon={faEnvelope} className='pr-4 h-5'/>
                <p>anasovi.ketevan@gmail.com</p>
            </a>
            <a href='https://www.buymeacoffee.com/K.T.A' className='flex place-items-center'>
                <FontAwesomeIcon icon={faMugHot} className='pr-4 h-5'/>
                <p>Buy me a coffee</p>
            </a>
        </div>
        <div className='pb-8'>
            <p className='text-center'>© 2022 Copyright: Ninea</p>
        </div>
    </div>
  )
}

export default Footer