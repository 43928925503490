import './App.css';
import Home from './components/pages/home';
import About from './components/pages/about';
import NoPage from './components/pages/noPage';
import Contact from './components/pages/contact';
import Header from './components/header';
import Footer from './components/footer';
import { Routes, Route } from 'react-router-dom';
import GalleryPage from './components/pages/galleryPage';

function App() {
  return (
    <div className="App">
      <Header/>
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/about' element={<About/>} />
          <Route path='/contact' element={<Contact/>} />
          <Route path='/gallery/:category' element={<GalleryPage/>} />
          <Route path='*' element={<NoPage/>} />
        </Routes>
      <Footer/>
    </div>
  );
}

export default App;
